import React from 'react';
import SEO from './seo';
import { motion } from 'framer-motion';

import '../styles/appQRPage.scss';

import QR_Android from '../images/QR_Android.svg';
import QR_iOS from '../images/QR_iOS.svg';
import X_Mockup from '../images/X_Mockup.svg';
import X_Mockup_2 from '../images/X_Mockup_2.svg';
import QR_Page_Background from '../images/QR_Page_Background.svg';

export default function AppQRPage({ platform }) {
    return (
        <div className="qrPageContainer">
            <img className="background" src={QR_Page_Background} alt="wavy background" />
            <SEO title="QR Page" />
            <motion.h1
                initial={{
                    opacity: 0,
                    scale: 0.8,
                    y: 20,
                }}
                animate={{
                    scale: 1,
                    opacity: 1,
                    y: 0,
                }}
                transition={{
                    duration: 1,
                }}
                className="pageHeader"
            >
                Download the Earth Hero mobile app now!
            </motion.h1>
            <motion.div
                initial={{
                    opacity: 0,
                    scale: 0.8,
                    y: 20,
                }}
                animate={{
                    scale: 1,
                    opacity: 1,
                    y: 0,
                }}
                transition={{
                    duration: 1,
                }}
                className="mainContent"
            >
                <div className="mobileContainer">
                    <img src={X_Mockup} alt="mobile app mockup 1" />
                    <img src={X_Mockup_2} alt="mobile app mockup 2" />
                </div>
                <div className="qrCodeContainer">
                    <img src={platform === 'android' ? QR_Android : QR_iOS} alt="QR code" />
                </div>
            </motion.div>
        </div>
    );
}
